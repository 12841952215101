import { useState } from "react";
import "./dao.css";
// import video from '../video/AVAX.mp4'

const Dao = () => {
  const [readmore, setReadmore] = useState(false);
  return (
    <div id="home" className="dao__container">
      <div className="dao__para">
        <h1
          data-aos="fade-up"
          className="dao__para__title"
          style={{ fontFamily: '"Monoton", cursive' }}
        >
          What is <br />
          diamond dao?
        </h1>
        <div className="daimond__para">
          <p data-aos="fade-left" className="diamond__sub__para">
            Diamond Dao is a global group of forward looking individuals who
            believe crypto is paramount to a future of peace and prosperity.{" "}
            <br />
            <br />
            Diamond Dao’s core values are: Community, Alpha gains, Purpose and
            Sustainability. <br />
            <br />
            Are you tired of spending all day looking for alpha and trying to
            get into the next big thing?
            <br />
            <br />
            Join a society of giga brains that pool their time, energy, wisdom
            and
            <br /> alpha together, and share in their juicy returns. <br />
            <br />
          </p>
          {/* {readmore && (
            <p data-aos="fade-left" className="diamond__sub__para">
              Inspired by the legends, carrying the legacy forward into the
              future, Diamond Dao NFTs were created by the illustrious Frogador
              Dali, and combine NAAS, FAAS, GAAS, and PAAS (presale as a
              service) to create eternal passive defi income. <br /> <br />
              Diamond Dao NFT Nodes are more than just income producing JPEGs.
              Each piece of art is a one of a kind, 1 in a million combination
              of hand designed traits that show both the beauty and strength of
              humanity, as well as the light that has created and sustained us
              all.
              <br /> <br />
              Verified by Chainlink (Link will go here) this phenomenal art
              collection embodies strength, brilliance, and value. <br /> <br />
              Built for frogs by frogs, both learning from the mistakes of
              others, as well as the genius innovation of past projects, we
              present to you not just an NFT that is worth the price of
              admission in its own right, but also, the Diamond Dao. <br />{" "}
            </p>
          )} */}

          <div className="dao__btn">
            {/* <button
              data-aos="fade-up"
              onClick={() => setReadmore(!readmore)}
              className="dao__btns"
            >
              {readmore === false ? "read less" : "read more"}
            </button> */}
          </div>
        </div>
      </div>
      {/* <div className='dao__videocard'>
        <div className='video'>
          <video
            style={{ background: "transparent" }}
            muted
            controls
            // src={video}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Dao;
