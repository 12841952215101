import Dao from "./components/Dao/Dao";
import "./App.css";
import Nav from "./components/Nav/Nav";
import Home from "./components/Home/Home";
import Utility from "./components/Utilities/Utility";
import Nodes from "./components/Nodes/Nodes";
import Roadmap from "./components/Roadmap/Roadmap";
import { Bot } from "./components/bot/Bot";
import Economics from "./components/Economics/Economics";
import Social from "./components/Socials/Social";
import Funds from "./components/Funds/Funds";
import ScrollToTop from "react-scroll-to-top";

function App() {
  return (
    <div className="App">
      <Social />
      <Nav />
      <Home />
      <Dao />
      {/* <Bot /> */}
      {/* <Utility /> */}
      {/* <Nodes /> */}
      {/* <Roadmap /> */}
      {/* <Economics /> */}
      <Funds />
      <ScrollToTop smooth />
    </div>
  );
}

export default App;
