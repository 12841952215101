import React from "react";
import "./Funds.css";

const Funds = () => {
  return (
    <div className="funds__container" id="fund">
      <br />
      <br />
      <br />
      <h1
        className="funds__main__title2"
        style={{ textAlign: "center" }}
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        To buy with USDC on ETH or BUSD on BSC go to{" "}
        <a
          style={{ color: "rgb(93, 206, 225)", textDecoration: "none" }}
          href="   https://dbk.finance"
          target="_blank"
        >
          https://dbk.finance
        </a>
      </h1>
      <h1
        className="funds__main__title"
        style={{ textAlign: "center" }}
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        How to get funds onto Avalanche
      </h1>{" "}
      <p className="funds__top_p" data-aos="fade-left" data-aos-duration="1000">
        If you use CEX like Binance etc simply make a metamask and send it to
        your metamask with steps below:
      </p>
      <p className="funds__top_p" data-aos="fade-left" data-aos-duration="1000">
        On Metamask click Network at the top, and then click add Network.
        <br />
        <br /> Put the following info in:
      </p>
      <div className="network__typer">
        <div className="f">
          <h1
            className="network__name"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Network Name
          </h1>
        </div>
        <div className="y" data-aos="fade-right" data-aos-duration="1000">
          <h2 className="network">AVAX</h2>
        </div>
      </div>
      <div className="network__typer2">
        <div className="f">
          <h1
            className="network__name2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Network Name
          </h1>
        </div>
        <div className="y" data-aos="fade-right" data-aos-duration="1000">
          <h2 className="network2">https://api.avax.network/ext/bc/C/rpc</h2>
        </div>
      </div>
      <div className="network__typer2">
        <div className="f">
          <h1
            className="network__name2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Chain ID
          </h1>
        </div>
        <div className="y" data-aos="fade-right" data-aos-duration="1000">
          <h2 className="network2">43114 or 0xa86a</h2>
        </div>
      </div>
      <div className="network__typer2">
        <div className="f">
          <h1
            className="network__name2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Currency Symbol
          </h1>
        </div>
        <div className="y">
          <h2
            className="network2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            AVAX
          </h2>
        </div>
      </div>
      <div className="network__typer2">
        <div className="f">
          <h1
            className="network__name2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Block Explorer URL
          </h1>
        </div>
        <div className="y">
          <h2
            className="network2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <a
              style={{ color: "rgb(93, 206, 225)", textDecoration: "none" }}
              href="https://snowtrace.io/ "
              target="blank"
            >
              snowtrace.io
            </a>
          </h2>
        </div>
      </div>
      <div
        className="fund__bottom__para"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <p>
          If you use DEX exchanges: <br />
          To bridge funds you can use any bridge you want. DYOR NFA:{" "}
          <a
            style={{ color: "rgb(93, 206, 225)", textDecoration: "none" }}
            href="https://synapseprotocol.com/"
            target="blank"
          >
            synapseprotocol
          </a>{" "}
          this bridge has worked for us. You will need to have your wallet
          connected to see the options of what Chain you can send from and you
          will need to be on that Chain in your Metamask for that option to
          populate. Most people bridge funds to USDC.e on the AVAX blockchain
          first. This bridge will also give you some AVAX for gas fees to be
          able to swap from USDC.e for example to AVAX on{" "}
          <a
            style={{ color: "rgb(93, 206, 225)", textDecoration: "none" }}
            href="https://traderjoexyz.com/trade"
            target="blank"
          >
            traderjoexyz
          </a>{" "}
          and once you have AVAX you can participate in the Private Presale
        </p>
      </div>
    </div>
  );
};

export default Funds;
