// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();

      let costPS = await store
        .getState()
        .blockchain.smartContract.methods.costPS()
        .call();

      let isPresale = await store
        .getState()
        .blockchain.smartContract.methods.preSalesMode()
        .call();

      let whitelistUser = await store
        .getState()
        .blockchain.smartContract.methods.isWhitelisted(
          store.getState().blockchain.account
        )
        .call();

      let ownerAddress = await store
        .getState()
        .blockchain.smartContract.methods.owner()
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          whitelistUser,
          isPresale,
          costPS,
          ownerAddress,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
