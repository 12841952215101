import React from "react";
import "./social.css";

const Social = () => {
  return (
    <ul className="ct-socials" id="hidden">
      <li>
        <a href="https://t.me/DBKRoyal/680" target="_blank">
          <img src="https://img.icons8.com/windows/50/000000/telegram-app.png" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/DiamondDaoNFT" target="_blank">
          <img src="https://img.icons8.com/ios/50/000000/twitter--v1.png" />
        </a>
      </li>
      <li>
        <a href="https://my.gabrieldisante.com/tour/diamonddao" target="_blank">
          <img src="https://img.icons8.com/ios/50/000000/git.png" />
        </a>
      </li>
      {/* <li>
        <a
          href='https://medium.com/@DiamondDaoNFT/diamond-dao-dcd54ab02200'
          target='_blank'
        >
          <img src='https://img.icons8.com/fluency-systems-regular/48/000000/medium-logo.png' />
        </a>
      </li> */}
      <li>
        <a href="https://discord.com/invite/zJRpUZepnV" target="_blank">
          <img src="https://img.icons8.com/ios/50/000000/discord-logo--v1.png" />
        </a>
      </li>
    </ul>
  );
};

export default Social;
