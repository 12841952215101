import React, { useEffect, useState } from "react";
import "./home.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import {
  StyledButton,
  StyledImg,
  StyledLink,
  ResponsiveWrapper,
  StyledLogo,
  StyledRoundButton,
} from "./homestyle.js";

const Home = () => {
  // const [claimingNft, setClaimingNft] = useState(false);
  // const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  // const [mintAmount, setMintAmount] = useState(1);
  const [mintedTokens, setmintedTokens] = useState(0);

  const [walletAddress, setwalletAdress] = useState(null);
  // const [networkId, setnetworkId] = useState(null);

  // const decrementMintAmount = () => {
  //   let newMintAmount = mintAmount - 1;
  //   if (newMintAmount < 1) {
  //     newMintAmount = 1;
  //   }
  //   setMintAmount(newMintAmount);
  // };

  // const incrementMintAmount = () => {
  //   let newMintAmount = mintAmount + 1;
  //   if (newMintAmount > 5) {
  //     newMintAmount = 5;
  //   }
  //   setMintAmount(newMintAmount);
  // };

  // async function connectMetamask() {
  //   if (typeof window.ethereum === "undefined") {
  //     throw new Error("Please install MetaMask to use this dApp!");
  //   }

  //   await window.ethereum.request({ method: "eth_requestAccounts" });

  //   const provider = new ethers.providers.Web3Provider(window.ethereum);

  //   const accounts = await provider.listAccounts();
  //   const address = accounts[0];

  //   const network = await provider.getNetwork();
  //   const networkId = network.chainId;

  //   if (networkId == 43114) {
  //     const aveCon = process.env.REACT_APP_DIA_DOA;

  //     const provider = new ethers.providers.Web3Provider(
  //       window.ethereum,
  //       "any"
  //     );
  //     await provider.send("eth_requestAccounts", []);

  //     const signer = provider.getSigner();

  //     const aveContract = connectContract(aveCon, diaDoaABI, signer);
  //     console.log(aveContract);

  //     const value = await aveContract.totalSupply();
  //     const bigNum = new BigNumber(value._hex);
  //     const decimalValue = bigNum.toString(10);
  //     setmintedTokens(decimalValue);
  //   }

  //   console.log(address, networkId);

  //   setwalletAdress(address);
  //   setnetworkId(networkId);
  // }

  // const mintToken = async () => {
  //   setClaimingNft(true);
  //   if (networkId == 43114) {
  //     const aveCon = process.env.REACT_APP_DIA_DOA;

  //     const provider = new ethers.providers.Web3Provider(
  //       window.ethereum,
  //       "any"
  //     );
  //     await provider.send("eth_requestAccounts", []);

  //     const signer = provider.getSigner();

  //     const aveContract = connectContract(aveCon, diaDoaABI, signer);
  //     console.log(aveContract);

  //     const check = await aveContract.preSalesMode();

  //     if (check) {
  //       const value = await aveContract.costPS();
  //       const bigNum = new BigNumber(value._hex);
  //       const cost = bigNum.toString(10);
  //       let totalCostWei = String(cost * mintAmount);

  //       let totalGasLimit = String(115000 * mintAmount);

  //       const res_tx = await aveContract.mint(mintAmount, {
  //         gasLimit: totalGasLimit,
  //         value: totalCostWei,
  //       });

  //       await res_tx.wait();
  //       console.log(res_tx);
  //       setClaimingNft(false);
  //     } else {
  //       const value = await aveContract.cost();
  //       const bigNum = new BigNumber(value._hex);
  //       const cost = bigNum.toString(10);
  //       let totalCostWei = String(cost * mintAmount);

  //       let totalGasLimit = String(115000 * mintAmount);

  //       const res_tx = await aveContract.mint(mintAmount, {
  //         gasLimit: totalGasLimit,
  //         value: totalCostWei,
  //       });

  //       await res_tx.wait();
  //       console.log(res_tx);
  //       setClaimingNft(false);
  //     }
  //   }
  // };

  // const connectContract = (address, abi, signerOrProvider) => {
  //   return new ethers.Contract(address, abi, signerOrProvider);
  // };

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: "",
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: "",
    WEI_COST: "",
    WEI_COST_WHITELIST: "",
    DISPLAY_COST: "",
    DISPLAY_COST_WHITELIST: "",
    GAS_LIMIT: "",
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    console.log(data.whitelistUser, "Whitelisted!");

    let cost = data.isPresale
      ? data.whitelistUser
        ? data.costPS
        : data.cost
      : data.cost;

    if (
      data.ownerAddress.localeCompare(blockchain.account, undefined, {
        sensitivity: "base",
      }) == 0
    ) {
      cost = 0;
    }

    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    console.log("ownerAddress: ", data.ownerAddress);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    console.log("Wallet address", blockchain.account);

    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit tofunft.com to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    console.log(blockchain.account);
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("./config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    window.process = {
      ...window.process,
    };
    getConfig();
  }, []);

  useEffect(() => {
    console.log("hello");
    getData();
  }, [blockchain.account]);

  return (
    <div className="homer">
      <div className="homerleft">
        <img src="https://i.imgur.com/ckDvanL.png" alt="" />
        <p className="fgs">
          If you are wondering why some NFTs are listed at a very high price
          level, (especially those in the Metaverse Oculus Gallery){" "}
          <a
            style={{ color: "rgb(93, 206, 225)", textDecoration: "none" }}
            target="_blank"
            href=" https://my.gabrieldisante.com/tour/diamonddao"
          >
            https://my.gabrieldisante.com/tour/diamonddao
          </a>{" "}
          It is because the rarities have not been announced yet and there will
          be some massive prizes (valued up to 20k) as the project develops and
          rarities are revealed for NFT holders.
        </p>
      </div>
      <div className="homerright">
        {/* <h1 className="topicheader">DIAMOND DAO</h1> */}
        {/* <video controls muted src={video} /> */}
        <div className="dao__videocard">
          <div className="video">
            {/* <video
              style={{ background: 'transparent' }}
              muted
              controls
              src={video}
            /> */}
          </div>
        </div>
        <h2 className="right__sub__title">
          {data.totalSupply} / 9999 Diamond Dao <br />
          MINTED
        </h2>
        <p className="sub_right__para">
          Over 2000 NFTs have been sold! You can now mint for 1 AVAX each
          <br /> All our NFT holders earn rewards from the #ROYALDBK project!
          <br /> Check our links for more information and access to the team.
        </p>
        {/* <div className="inputsect">
          <input className="textInput" type="text" />
          <button
            className="OIKNO"
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            Connect
          </button>
        </div> */}

        <>
          {blockchain.account == null || blockchain.smartContract === null ? (
            <div className="inputsect">
              <input className="textInput" type="text" />
              <button
                className="OIKNO"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                Connect
              </button>
            </div>
          ) : (
            <>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                {feedback}
              </s.TextDescription>
              <s.SpacerMedium />
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                <StyledRoundButton
                  style={{
                    lineHeight: 0.4,
                    color: "white",
                    backgroundColor: "#ffc700",
                  }}
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    decrementMintAmount();
                  }}
                >
                  -
                </StyledRoundButton>
                <s.SpacerMedium />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {mintAmount}
                </s.TextDescription>
                <s.SpacerMedium />
                <StyledRoundButton
                  style={{
                    lineHeight: 0.4,
                    color: "white",
                    backgroundColor: "#ffc700",
                  }}
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    incrementMintAmount();
                  }}
                >
                  +
                </StyledRoundButton>
              </s.Container>
              <s.SpacerSmall />
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                <StyledButton
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    claimNFTs();
                    getData();
                  }}
                >
                  {claimingNft ? "BUSY" : "BUY"}
                </StyledButton>

                {/* <StyledButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "BUSY" : "BUY"}
                  </StyledButton> */}
              </s.Container>
            </>
          )}
        </>

        {/* {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
          <>
            <s.TextTitle style={{ textAlign: "center", color: "white" }}>
              The sale has ended.
            </s.TextTitle>
            <s.TextDescription style={{ textAlign: "center", color: "white" }}>
              You can still find {CONFIG.NFT_NAME} on
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
          </>
        ) : (
          <>
            {walletAddress == null && networkId == null ? (
              <div className="inputsect">
                <input className="textInput" type="text" />
                <button className="OIKNO" onClick={(e) => connectMetamask()}>
                  Connect
                </button>
              </div>
            ) : (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{
                      lineHeight: 0.4,
                      color: "white",
                      backgroundColor: "#ffc700",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    style={{
                      lineHeight: 0.4,
                      color: "white",
                      backgroundColor: "#ffc700",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerSmall />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      mintToken();
                    }}
                  >
                    {claimingNft ? "BUSY" : "BUY"}
                  </StyledButton>

                  {/* <StyledButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "BUSY" : "BUY"}
                  </StyledButton>
                </s.Container>
              </>
            )}
          </>
        )} */}
      </div>
    </div>
  );
};

export default Home;
