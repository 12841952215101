import './Nav.css'
import { Link } from 'react-scroll'

import { FiAlignJustify } from 'react-icons/fi'
import { useState } from 'react'

export default function Nav () {
  const [open, setOpen] = useState(false)

  const whitelist = () => {
    window.open(
      'https://linktr.ee/ROYAL_TOKEN',
      '_blank',
      'noopener,noreferrer'
    )
  }

  return (
    <div className='nav-container'>
      <ul className='nav-list'>
        {/* <li className="nav-item">
          <Link
            to="Home"
            spy={true}
            smooth="true"
            duration={300}
            className="nav-anchor"
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="node" smooth="true" duration={300} className="nav-anchor">
            Passive Income
          </Link>
        </li>
        <li className="nav-item">
          <Link to="profit" smooth="true" duration={300} className="nav-anchor">
            Profit
          </Link>
        </li>
        <li className="nav-item">
          <Link to="road" smooth={true} duration={300} className="nav-anchor">
            Roadmap
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="enomics"
            smooth={true}
            duration={300}
            className="nav-anchor"
          >
            NFTokenomics
          </Link>
        </li> */}

        <li className='nav-item'>
          <Link to='fund' smooth={true} duration={300} className='nav-anchor'>
            AVAX
          </Link>
        </li>
      </ul>
      <div className='nav-btn'>
        <button onClick={() => whitelist()}>
          <a
            smooth='true'
            style={{ textDecoration: 'none', color: 'white' }}
            href='https://linktr.ee/ROYAL_TOKEN'
            target='_blank'
          />
          Whitelist
        </button>
      </div>
      <div className='nav-hamburger' onClick={() => setOpen(!open)}>
        <FiAlignJustify className='hamburger-ico' />
      </div>
      <ul
        onClick={() => setOpen(false)}
        className='nav-hamburger-list'
        style={{ right: open ? '0px' : '-100vw' }}
      >
        {/* <li className="nav-hamburger-item">
          {" "}
          <a smooth="true" href="#Home" duration={300} className="nav-anchor">
            Home
          </a>
        </li>
        <li className="nav-hamburger-item">
          <a smooth="true" href="#node" duration={900} className="nav-anchor">
            Passive Income
          </a>
        </li>
        <li className="nav-hamburger-item">
          {" "}
          <a smooth="true" href="#profit" duration={300} className="nav-anchor">
            Profit
          </a>
        </li>
        <li className="nav-hamburger-item">
          {" "}
          <a
            href="
          #road"
            smooth="true"
            duration={300}
            className="nav-anchor"
          >
            Roadmap
          </a>
        </li>
        <li className="nav-hamburger-item">
          <a
            smooth="true"
            href="#enomics"
            duration={300}
            className="nav-anchor"
          >
            NFTokenomics
          </a>
        </li> */}
        <li className='nav-hamburger-item'>
          <a
            smooth='true'
            style={{ textDecoration: 'none', color: 'white' }}
            href=' #fund'
          >
            AVAX
          </a>
        </li>

        <div className='nav-btn1'>
          <button onClick={() => whitelist()}>
            <a
              smooth='true'
              style={{ textDecoration: 'none', color: 'black' }}
              href='https://linktr.ee/diamondwoman'
              target='_blank'
            >
              Whitelist
            </a>
          </button>
        </div>
      </ul>
    </div>
  )
}
